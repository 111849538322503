import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersDocuments.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  PoDocument,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrdersDocuments() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const documentSectionsMap = {
    PURCHASE_ORDER: "Purchase Order",
    INVOICE: "Invoice",
    BILLTY: "Billty",
    PROOF_OF_DELIVERY: "Proof of Delivery",
    RETURN_INVOICE: "Return Invoice",
    CREDIT_NOTE: "Credit Note",
    DEBIT_NOTE: "Debit Note",
  };
  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [poDocuments, setPoDocuments] = useState<PoDocument[]>([]);
  const [poDocumentsMap, setPoDocumentsMap] =
    useState<Record<string, PoDocument[]>>();

  useEffect(() => {
    fetchPODocuments(po_id);
  }, []);

  const createDocumentMap = (documents: PoDocument[]) => {
    setPoDocumentsMap(
      documents.reduce((map, doc) => {
        if (!map[doc.file_type]) {
          map[doc.file_type] = [];
        }
        map[doc.file_type].push(doc);
        return map;
      }, {} as Record<string, PoDocument[]>)
    );
  };

  const fetchPODocuments = async (purchase_order_id: any) => {
    setShowLoadingPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.fetchPODocuments(
        purchase_order_id.toString()
      );

      setPoDocuments(response.data);
      createDocumentMap(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in getting po_documents : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const downloadFileFromWeb = async (file_id: any, saved_file_name: string) => {
    setShowDownloadPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadPoFileFromWeb(file_id);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = saved_file_name; // Use the original file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setShowDownloadPage(false);
    } catch (error) {
      console.error("Error in downloading file : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowDownloadPage(false);
    }
  };

  return (
    <div className="po-documents-container">
      {Object.entries(documentSectionsMap).map(([key, value], index) => {
        const docs: PoDocument[] = poDocumentsMap
          ? poDocumentsMap[key] || []
          : [];
        return (
          <div className="vendor-action-buttons-container incoming-purchase-order-details-table-container pt-4">
            <div className="po-document-section-heading">{value}</div>
            {docs.length === 0 ? (
              <div className="po-document-section-no-document mt-3">
                No document was found
              </div>
            ) : (
              <div className="incoming-purchase-order-file-details-table-container pt-4">
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  className="table-auto-width"
                >
                  <thead>
                    <tr>
                      <th>File ID</th>
                      <th>Uploaded At</th>
                      <th>Uploaded By</th>
                      <th>File Name</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((doc, index) => {
                      return (
                        <tr className="catalogues-search-bar-row">
                          <td className="nowrap">{doc.id}</td>
                          <td>
                            {DateTimeUtils.formatDateTime(doc.created_at)}
                          </td>
                          <td>{doc.file_uploaded_by}</td>
                          <td>{doc.file_name}</td>
                          <td>
                            <Download
                              size={15}
                              color="#007bff"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadFileFromWeb(doc.id, doc.saved_file_name)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        );
      })}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersDocuments;
